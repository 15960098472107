'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Section, Container } from '@audioeye-marketing/ui'
import { translateSectionStyles } from '@/lib/translate-section-styles'

const BasicContent = ({ blok, headingLevel }) => {
  const sectionStyles = translateSectionStyles(blok?.section_styles[0])

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles} textAlign="center">
      <Container constraint="sm">
        {blok?.text_content?.map((currentTextContent) => (
          <StoryblokComponent
            key={currentTextContent?._uid}
            blok={currentTextContent}
            alignItems="center"
            headingLevel={headingLevel}
            backgroundColor={sectionStyles?.backgroundColor}
          />
        ))}
      </Container>
    </Section>
  )
}

export default BasicContent
