'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Section, Container, Stack, Typography, Box, useTheme, useMediaQuery } from '@audioeye-marketing/ui'
import { translateSectionStyles } from '@/lib/translate-section-styles'

const Banner = ({ blok, headingLevel }) => {
  const sectionStyles = translateSectionStyles(blok.section_styles[0])
  const theme = useTheme()
  const lowerThanSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles} textAlign="left">
      <Container constraint="lg">
        <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" justifyContent="space-between" spacing={4}>
          <Typography level={`h${headingLevel}`} sx={{ marginBottom: '0 !important' }}>
            {blok?.heading}
          </Typography>
          <Box
            sx={{
              width: { xs: '100%', md: 'auto' },
              minWidth: { md: '300px' },
              display: { md: 'flex' },
              justifyContent: { md: 'flex-end' },
            }}
          >
            {blok?.cta?.map((currentCta) => (
              <StoryblokComponent
                key={currentCta?._uid}
                blok={currentCta}
                backgroundColor={sectionStyles?.backgroundColor}
                fullWidth={lowerThanSmallScreen}
              />
            ))}
          </Box>
        </Stack>
      </Container>
    </Section>
  )
}

export default Banner
