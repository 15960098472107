'use client'

import React, { createContext } from 'react'

const Form = createContext()

const FormProvider = ({ formName, hubspotFormId, language, children }) => {
  return <Form.Provider value={{ formName, hubspotFormId, language }}>{children}</Form.Provider>
}

const useFormContext = () => {
  const context = React.useContext(Form)

  if (context === undefined) {
    throw new Error('useFormContext must be used within a FormProvider')
  }
  return context
}

export { FormProvider, useFormContext }
