'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { getResolvedStoryblokStory } from '@/lib/get-resolved-storyblok-story'

const Frame = ({ blok, children, rels }) => {
  const header = getResolvedStoryblokStory(blok?.header, rels)
  const footer = getResolvedStoryblokStory(blok?.footer, rels)

  return (
    <div {...storyblokEditable(blok)}>
      {Boolean(header) && <StoryblokComponent blok={header} />}
      {/* ID #content for SkipLink.jsx */}
      <main id="content" role="main" tabIndex={-1}>
        {blok?.before_content?.map((currentSection, index) => (
          <StoryblokComponent
            blok={currentSection}
            key={currentSection?._uid}
            headingLevel={index === 0 ? 1 : 2}
            imagePriority={index === 0 || index === 1}
          />
        ))}

        {children}

        {blok?.after_content?.map((currentSection) => (
          <StoryblokComponent blok={currentSection} key={currentSection?._uid} headingLevel={2} />
        ))}
      </main>
      {Boolean(footer) && <StoryblokComponent blok={footer} />}
    </div>
  )
}

export default Frame
