'use client'

import { useState } from 'react'
import Drawer from '@mui/joy/Drawer'
import DialogTitle from '@mui/joy/DialogTitle'
import DialogContent from '@mui/joy/DialogContent'
import ModalClose from '@mui/joy/ModalClose'
import Stack from '@mui/joy/Stack'
import Sheet from '@mui/joy/Sheet'
import HamburgerMenuIcon from '@mui/icons-material/Menu'
import { IconButton } from '@mui/joy'
import { trackEvent } from '../../../lib/track-event'
import colors from '../../../theme/colors'

const MobileHeader = ({ logo, navItems, ctas }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <IconButton
        variant="soft"
        color="neutral"
        onClick={() => {
          trackEvent('Mobile Navigation Toggled', {
            'Mobile Navigation Open': true,
          })
          setOpen(true)
        }}
        sx={{ padding: '9px', display: { xs: 'flex', md: 'none' } }}
      >
        <HamburgerMenuIcon />
      </IconButton>
      <Drawer
        open={open}
        size="md"
        variant="solid"
        color="primary"
        anchor="right"
        onClose={() => {
          trackEvent('Mobile Navigation Toggled', {
            'Mobile Navigation Open': false,
          })
          setOpen(false)
        }}
        slotProps={{
          content: {
            sx: {
              bgcolor: 'transparent',
              p: 3,
              boxShadow: 'none',
              width: { xs: '100%', sm: '65%' },
            },
          },
        }}
        sx={{
          zIndex: 2,
        }}
      >
        <Sheet
          sx={{
            borderRadius: 'md',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            overflow: 'auto',
          }}
        >
          <DialogTitle sx={{ p: 3 }}>{logo}</DialogTitle>
          <ModalClose variant="soft" color="neutral" />
          <DialogContent sx={{ p: 3, gap: 2 }}>{navItems}</DialogContent>
          <Stack
            direction="column"
            justifyContent="space-between"
            useFlexGap
            spacing={2}
            sx={{ p: 3, backgroundColor: colors.neutral100 }}
          >
            {ctas}
          </Stack>
        </Sheet>
      </Drawer>
    </>
  )
}

export default MobileHeader
