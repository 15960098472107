import React from 'react'
import JoyInput from '@mui/joy/Input'
import { useFormContext } from '../Form/FormProvider'
import { trackEvent } from '../../../lib/track-event'

const Input = React.forwardRef(({ children, endDecorator, onBlur, onFocus, disableTracking, ...rest }, ref) => {
  const { formName, hubspotFormId } = useFormContext()

  return (
    <JoyInput
      ref={ref}
      onBlur={(e) => {
        onBlur && onBlur(e)

        if (!disableTracking) {
          trackEvent('Form Value Changed', {
            'Form Name': formName,
            'Form Hubspot ID': hubspotFormId,
            'Form Element Name': rest.name,
            'Form Element Type': 'input',
            'New Value': e.target.value,
          })
        }
      }}
      onFocus={(e) => {
        onFocus && onFocus(e)

        if (!disableTracking) {
          trackEvent('Form Value Focused', {
            'Form Name': formName,
            'Form Hubspot ID': hubspotFormId,
            'Form Element Name': rest.name,
            'Form Element Type': 'input',
            'Current Value': e.target.value,
          })
        }
      }}
      endDecorator={endDecorator}
      {...rest}
    >
      {children}
    </JoyInput>
  )
})

Input.displayName = 'Input'

export default Input
