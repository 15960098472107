'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { TabPanel, Stack } from '@audioeye-marketing/ui'

const Tab = ({ blok, value, headingLevel, backgroundColor, imagePriority, ...rest }) => {
  return (
    <TabPanel {...storyblokEditable(blok)} value={value} sx={{ pl: { md: 8 } }} {...rest}>
      <Stack spacing={4} sx={{ textAlign: 'left' }} alignItems="center">
        {blok?.media?.map((currentMedia) => {
          return (
            <StoryblokComponent
              key={currentMedia?._uid}
              blok={currentMedia}
              headingLevel={headingLevel}
              backgroundColor={backgroundColor}
              imagePriority={imagePriority}
            />
          )
        })}

        {blok?.text_content?.map((currentTextContent) => (
          <StoryblokComponent
            key={currentTextContent?._uid}
            blok={currentTextContent}
            headingLevel={headingLevel}
            backgroundColor={backgroundColor}
          />
        ))}
      </Stack>
    </TabPanel>
  )
}

export default Tab
