'use client'

import Image from 'next/image'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Header as UIHeader, MobileHeader, Stack, Link } from '@audioeye-marketing/ui'
import AudioEyeLogo from '@/assets/audioeye-logo-black-purple-symbol.svg'
import AudioEyeSymbolOnlyPurple from '@/assets/audioeye-symbol-only-purple.svg'

const createCtas = (ctas, fullWidth) => {
  return ctas?.map((cta) => {
    return cta?.component === 'Link' ? (
      <StoryblokComponent key={cta?._uid} blok={cta} color="black" />
    ) : (
      <StoryblokComponent key={cta?._uid} blok={cta} color="primary" fullWidth={fullWidth} />
    )
  })
}

const Logo = ({ logoLink, mobile }) => {
  const image = (
    <Image
      priority
      src={mobile ? AudioEyeSymbolOnlyPurple : AudioEyeLogo}
      width={mobile ? 30 : 130}
      height={mobile ? 30 : 31}
      alt="audio eye logo"
    />
  )

  return !logoLink?.cached_url ? (
    image
  ) : (
    <Link
      href={
        logoLink?.linktype === 'story'
          ? logoLink?.cached_url === 'home'
            ? '/'
            : `/${logoLink?.cached_url}`
          : logoLink?.cached_url || '/'
      }
    >
      {image}
    </Link>
  )
}

const Header = ({ blok }) => {
  const navItems = blok?.navigation_items?.map((navigationItem) => (
    <StoryblokComponent key={navigationItem?._uid} blok={navigationItem} color="black" />
  ))

  return (
    <UIHeader {...storyblokEditable(blok)}>
      <Stack direction="row" spacing={8} justifyContent="space-between">
        <Stack direction="row" spacing={{ xs: 4, lg: 10 }}>
          <Logo logoLink={blok?.logo_link} />

          {navItems?.length > 0 && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={4}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              {navItems}
            </Stack>
          )}
        </Stack>

        {blok?.ctas?.length > 0 && (
          <Stack direction="row" spacing={3} sx={{ display: { xs: 'none', md: 'flex' } }}>
            {createCtas(blok?.ctas)}
          </Stack>
        )}

        {blok?.navigation_items?.length > 0 && (
          <MobileHeader
            logo={<Logo logoLink={blok?.logo_link} mobile />}
            navItems={navItems}
            ctas={createCtas(blok?.ctas, true)}
          />
        )}
      </Stack>
    </UIHeader>
  )
}

export default Header
