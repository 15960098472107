'use client'

import Image from 'next/image'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Footer as MarketingFooter, Stack, Container } from '@audioeye-marketing/ui'
import renderRichText from '@/lib/render-rich-text'
import AudioEyeLogoWhiteTealSymbol from '@/assets/audioeye-logo-white-teal-symbol.svg'

const Footer = ({ blok }) => {
  const hasRegulatoryLinks = blok?.regulatory_links?.length > 0
  const hasSocialMediaLinks = blok?.social_media_links?.length > 0
  const hasLegalContent = blok.legal?.content[0]?.content?.length > 0
  const noContent = !hasRegulatoryLinks && !hasSocialMediaLinks && !hasLegalContent

  return (
    <MarketingFooter {...storyblokEditable(blok)}>
      <Stack direction="column" spacing={{ xs: 4, md: 10 }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={noContent ? 'flex-start' : 'center'}
          flexWrap="wrap"
          gap={4}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            spacing={{ xs: 4, md: 8 }}
          >
            <Image src={AudioEyeLogoWhiteTealSymbol} width={145} height={31} alt="audio eye logo" />
            {Boolean(hasRegulatoryLinks) && (
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 4 }}>
                {blok?.regulatory_links?.map((link) => (
                  <StoryblokComponent key={link?._uid} textAlign="center" color="white" blok={link} />
                ))}
              </Stack>
            )}
          </Stack>
          {Boolean(hasSocialMediaLinks) && (
            <Stack direction="row" spacing={{ xs: 1, md: 2 }}>
              {blok?.social_media_links?.map((link) => (
                <StoryblokComponent key={link?._uid} textAlign="center" color="white" blok={link} />
              ))}
            </Stack>
          )}
        </Stack>
        {Boolean(hasLegalContent) && (
          <Container constraint="md">
            <Stack spacing={1}>
              {renderRichText(blok?.legal, {
                NODE_PARAGRAPH: {
                  textAlign: { xs: 'left', md: 'center' },
                  color: 'neutral',
                  level: 'body-xs',
                },
              })}
            </Stack>
          </Container>
        )}
      </Stack>
    </MarketingFooter>
  )
}

export default Footer
