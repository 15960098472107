'use client'

const getHiddenFieldValue = (fieldName) => {
  const utmParams = JSON.parse(window.localStorage.getItem('utm_params'))

  switch (fieldName) {
    case 'utm_campaign':
      return utmParams?.utm_campaign || ''
    case 'utm_content':
      return utmParams?.utm_content || ''
    case 'utm_medium':
      return utmParams?.utm_medium || ''
    case 'utm_source':
      return utmParams?.utm_source || ''
    case 'utm_term':
      return utmParams?.utm_term || ''
    case 'gclid__c':
      return JSON.parse(window.localStorage.getItem('google_ads_gclid')) || ''
    // * Mutiny - set via GTM tag called "Local Storage - Mutiny Experience Impression"
    case 'mutiny_experience_name':
      return localStorage.getItem('mutiny_experience_name') || ''
    case 'mutiny_segment_name':
      return localStorage.getItem('mutiny_segment_name') || ''
    case 'mutiny_variation_name':
      return localStorage.getItem('mutiny_variation_name') || ''
    default:
      return ''
  }
}

export default getHiddenFieldValue
