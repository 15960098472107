import { useState } from 'react'
import {
  Box,
  Input,
  Button,
  Form,
  FormControl,
  FormLabel,
  FormHelperText,
  Stack,
  Typography,
} from '@audioeye-marketing/ui'

const PasswordProtectedPage = ({ cmsPassword, setIsAuthenticated }) => {
  const [password, setPassword] = useState('')
  const [validationError, setValidationError] = useState('')

  const handlePasswordSubmit = (e) => {
    e.preventDefault()
    if (password === cmsPassword) {
      setIsAuthenticated(true)
      setValidationError('')
    } else {
      setPassword('')
      setValidationError('Incorrect password')
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Box sx={{ width: '80%', maxWidth: '600px' }}>
        <Form name="Site Scan URL" onSubmit={handlePasswordSubmit}>
          <FormControl error={Boolean(validationError)}>
            <Stack spacing={1}>
              <FormLabel>
                <Typography>
                  <strong>Password</strong>
                </Typography>
              </FormLabel>
              <Box>
                <Input
                  sx={{ '--Input-decoratorChildHeight': '45px' }}
                  name="password"
                  placeholder="Enter password"
                  type="text"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endDecorator={
                    <Button
                      variant="solid"
                      size="lg"
                      color="primary"
                      type="submit"
                      sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    >
                      Submit
                    </Button>
                  }
                />
              </Box>
              {Boolean(validationError) && <FormHelperText>{validationError}</FormHelperText>}
            </Stack>
          </FormControl>
        </Form>
      </Box>
    </Box>
  )
}

export default PasswordProtectedPage
