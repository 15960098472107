'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import { Typography, Stack, theme, Card as UICard } from '@audioeye-marketing/ui'
import renderRichText from '@/lib/render-rich-text'

const Card = ({ blok, backgroundColor, imagePriority, ...rest }) => {
  // If no background color is chosen, then default to white
  const determinedBackgroundColor = backgroundColor || theme?.colors?.white
  let cardBackgroundColor = determinedBackgroundColor

  if (blok.card) {
    switch (determinedBackgroundColor) {
      case '#FFFFFF':
        cardBackgroundColor = theme.colors.neutral50
        break
      case '#F7F9FC':
        cardBackgroundColor = theme.colors.white
        break
    }
  }

  return (
    <UICard
      {...storyblokEditable(blok)}
      variant="plain"
      sx={{
        borderRadius: '8px',
        padding: '32px',
        backgroundColor: cardBackgroundColor,
        textAlign: 'left',
        height: '100%',
      }}
      {...rest}
    >
      <Stack spacing={2} justifyContent="space-between">
        <Stack spacing={2}>
          {Boolean(blok?.icon?.length > 0) &&
            blok.icon?.map((currentIcon) => (
              <StoryblokComponent key={currentIcon?._uid} blok={currentIcon} imagePriority={imagePriority} />
            ))}
          <Typography level="title-lg">{blok?.heading}</Typography>
          {renderRichText(blok?.text)}
        </Stack>
        {blok?.cta?.map((currentCta) => (
          <StoryblokComponent key={currentCta?._uid} blok={currentCta} />
        ))}
      </Stack>
    </UICard>
  )
}

export default Card
