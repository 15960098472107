'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Section, Container, Grid, Stack } from '@audioeye-marketing/ui'
import { translateSectionStyles } from '@/lib/translate-section-styles'

const SplitContent = ({ blok, headingLevel, imagePriority }) => {
  const sectionStyles = translateSectionStyles(blok?.section_styles[0])

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles} textAlign={{ xs: 'center', lg: 'left' }}>
      <Container constraint="lg">
        <Grid direction={blok?.reverse_content ? 'row-reverse' : 'row'} container spacing={8}>
          <Grid xs={12} lg={6}>
            <Stack justifyContent="center" sx={{ height: '100%', maxWidth: '700px', margin: 'auto' }}>
              {blok?.text_content?.map((currentTextContent) => (
                <StoryblokComponent
                  key={currentTextContent?._uid}
                  blok={currentTextContent}
                  backgroundColor={sectionStyles?.backgroundColor}
                  headingLevel={headingLevel}
                  alignItems={{ xs: 'center', lg: 'flex-start' }}
                />
              ))}
            </Stack>
          </Grid>
          <Grid xs={12} lg={6}>
            <Stack alignItems={{ xs: 'center', lg: 'flex-end' }}>
              {blok?.media?.map((currentMedia) => (
                <StoryblokComponent key={currentMedia?._uid} blok={currentMedia} imagePriority={imagePriority} />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

export default SplitContent
