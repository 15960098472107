import parse, { attributesToProps, domToReact } from 'html-react-parser'
import Typography from '../../../../DataDisplay/Typography/Typography'
import Link from '../../../../Navigation/Link/Link'

/**
 * @param {string} htmlString - string that may contain HTML tags
 * @return {object} - React components to render the HTML string
 */
const parseHtmlString = (htmlString) => {
  // Check if the input is just a regular string without any HTML tags
  if (!/<[^>]*>/g.test(htmlString)) {
    return <Typography level="body-xs">{htmlString}</Typography>
  }

  return parse(htmlString, {
    replace: ({ attribs, name, children }) => {
      const props = attributesToProps(attribs)

      if (name === 'p') {
        return (
          <Typography level="body-xs" {...props}>
            {domToReact(children)}
          </Typography>
        )
      }

      if (name === 'a') {
        return <Link {...props}>{domToReact(children)}</Link>
      }
    },
  })
}

export default parseHtmlString
