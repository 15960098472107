'use client'

import React, { useState } from 'react'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Modal as UIModal, ModalDialog, DialogContent, ModalClose } from '@audioeye-marketing/ui'

const Modal = ({ blok, trigger, ...rest }) => {
  const [open, setOpen] = useState(false)
  const clonedTrigger = React.cloneElement(trigger, {
    onClick: () => {
      setOpen(true)
    },
    ...storyblokEditable,
    // Next two props are needed for when images are the trigger
    onKeyDown: (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault()
        setOpen(true)
      }
    },
    tabIndex: 0,
  })

  return (
    <>
      {clonedTrigger}
      <UIModal {...storyblokEditable(blok)} open={open} onClose={() => setOpen(false)} {...rest}>
        <ModalDialog
          size="lg"
          layout="center"
          sx={{
            width: {
              xs: '90%',
              lg: blok?.content[0]?.component === 'Form' ? '50%' : '80%',
            },
            backgroundColor: 'white',
          }}
        >
          <ModalClose variant="soft" />
          <DialogContent sx={{ mt: 4 }}>
            <StoryblokComponent blok={blok?.content[0]} />
          </DialogContent>
        </ModalDialog>
      </UIModal>
    </>
  )
}

export default Modal
