'use client'

import { useState } from 'react'
import { useRouter } from 'next/navigation'
import { storyblokEditable } from '@storyblok/react'
import { useQuery } from '@tanstack/react-query'
import { HubspotForm, Box, Typography, trackEvent, identifyUser } from '@audioeye-marketing/ui'
import { getBrowserCookie } from '@/lib/get-browser-cookie'

const Form = ({ blok }) => {
  const formId =
    process.env.NEXT_PUBLIC_APP_ENV !== 'production'
      ? blok?.hubspot_form?.stagingHubspotFormID
      : blok?.hubspot_form?.productionHubspotFormID

  const { isPending, error, data } = useQuery({
    queryKey: ['hubspotFormData', formId],
    queryFn: async () => await fetch(`/api/forms/${formId}/`).then((res) => res.json()),
    staleTime: 1000 * 60 * 30, // Data will be fresh for 30 minutes
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [submissionError, setSubmissionError] = useState(false)
  const [submissionErrorMessage, setSubmissionErrorMessage] = useState({
    title: 'Having troubles at the moment.',
    body: 'Please try again later.',
  })
  const router = useRouter()

  if (error || submissionError) {
    if (error) {
      trackEvent('API Exception', {
        Label: 'Error with React Query pulling Hubspot form data',
        'Full Exception': error,
      })
    }

    return (
      <Box sx={{ textAlign: 'center' }} {...storyblokEditable(blok)}>
        <Typography level="h2">{submissionErrorMessage.title}</Typography>
        <Typography level="body-lg">{submissionErrorMessage.body}</Typography>
      </Box>
    )
  }

  if (!formId) return null

  // If the form has a Form Name field (typically hidden field) in Hubspot, use that as the form name
  const formNameField = data?.form?.fieldGroups
    ?.flatMap((group) => group.fields)
    ?.find((field) => field?.name === 'form_name')
  const formName = formNameField?.defaultValue || data?.form?.name
  const formLanguage = data?.form?.configuration?.language || 'en'

  return (
    <HubspotForm
      {...storyblokEditable(blok)}
      formId={formId}
      formName={formName} // prefer to use form name from form data, but fallback to the field value if it's not available
      language={formLanguage}
      isLoading={isPending}
      fieldGroups={data?.form?.fieldGroups}
      submitButtonText={data?.form?.displayOptions?.submitButtonText}
      legalConsentOptions={data?.form?.legalConsentOptions}
      isSubmitting={isSubmitting}
      isFormSubmitted={isFormSubmitted}
      onSubmit={async (submissionData) => {
        setIsSubmitting(true)

        const legalConsent = submissionData?.LEGAL_CONSENT
        delete submissionData.LEGAL_CONSENT
        let legalConsentOptions = {}

        if (legalConsent) {
          legalConsentOptions = {
            consent: {
              consentToProcess: legalConsent?.processing === 'true',
              text: data?.form?.legalConsentOptions?.consentToProcessCheckboxLabel?.replaceAll(/<[^>]*>/gi, ''),
              communications: data?.form?.legalConsentOptions?.communicationsCheckboxes?.map((currentCheckbox) => {
                return {
                  text: currentCheckbox?.label?.replaceAll(/<[^>]*>/gi, ''),
                  subscriptionTypeId: currentCheckbox?.subscriptionTypeId,
                  value: legalConsent[`subscription_type_${currentCheckbox?.subscriptionTypeId}`] === 'true',
                }
              }),
            },
          }
        }

        // * A form may have dependent fields, if so, we need to remove them from the submission data if they are not filled out or Hubspot will fail the submission
        const dependentFields = []
        data?.form?.fieldGroups?.forEach((fieldGroup) => {
          fieldGroup?.fields?.forEach((field) => {
            if (field?.dependentFields) {
              dependentFields?.push(field?.name)
            }
          })
        })

        const formData = {
          ...Object.keys(submissionData).reduce((acc, key) => {
            // Include the field if it has a value AND it's not the password field (don't want PII in Hubspot)
            if (submissionData[key] && key !== 'password') {
              acc[key] = submissionData[key]
            }
            return acc
          }, {}),
        }

        let magicLink = ''
        if (formName === 'Free Trial Form') {
          const createAccount = await fetch(
            `${process.env.NEXT_PUBLIC_AE_MONO_ENDPOINT}/v2/user/create-from-marketing`,
            {
              method: 'POST',
              headers: {
                'Content-type': 'application/json',
              },
              body: JSON.stringify({
                firstName: formData?.firstname,
                lastName: formData?.lastname,
                email: formData?.email,
                phone: formData?.phone,
                company: formData?.company,
                url: formData?.website,
                username: formData?.email,
                password: submissionData?.password,
                customerType: 'Direct',
                planId: 'simple-monthly',
              }),
            }
          )

          const accountResponse = await createAccount.json()

          if (createAccount.status === 409) {
            trackEvent(`API Exception`, {
              Label: 'Magic Link Existing User',
              'Full Exception': accountResponse,
            })
            setSubmissionError(true)
            setSubmissionErrorMessage({
              title: 'Existing User',
              body: 'Please sign into your account',
            })
            return
          }

          // possible API 404
          if (createAccount.status === 404) {
            trackEvent(`API Exception`, {
              Label: 'Magic Link 404',
              'Full Exception': accountResponse,
            })
            setSubmissionError(true)
            return
          }

          if (!createAccount.ok) {
            trackEvent(`API Exception`, {
              Label: 'Magic Link Error',
              'Full Exception': accountResponse,
            })
            setSubmissionError(true)
            return
          }

          trackEvent('Account Created', {
            Email: formData.email,
            Website: formData.website || '',
            'Company Name': formData.company || '',
            'Subscription ID': accountResponse.subscriptionId || '',
            'Portal Account ID': accountResponse.accountId || '',
            'Portal User ID': accountResponse.userId || '',
          })

          magicLink = accountResponse.magicLink
          formData.user_id__c = accountResponse.userId
          formData['0-2/portal_account_id'] = accountResponse.accountId
        }

        try {
          const response = await fetch(`/api/forms/submit/${formId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              formData,
              legalConsentOptions,
              context: {
                hutk: getBrowserCookie('hubspotutk'),
                pageUri: window && window?.location?.href,
                pageName: document?.title || '',
              },
            }),
          })

          const result = await response.json()

          if (response.ok) {
            if (result?.responseMessage?.status !== 'error') {
              identifyUser(formData?.email)

              trackEvent('Hubspot Form Submitted', {
                'Hubspot Form Name': formName,
                'Form Hubspot ID': formId,
                'Job Title': formData?.jobtitle || '',
                'Company Name': formData?.company || '',
                Website: formData?.website || '',
                'Agency or Freelancer': formData?.agency_or_freelancer__c || '',
                Email: formData?.email || '',
              })

              if (formName === 'Free Trial Form') {
                if (magicLink) {
                  router.push(magicLink)
                } else {
                  setIsFormSubmitted(true)
                }
              } else if (formName === 'Free Consultation Form') {
                const utmParams = JSON.parse(window.localStorage.getItem('utm_params'))

                if (utmParams.utm_campaign !== 'toolbar_referral') {
                  const revenue = parseInt(
                    formData?.['0-2/annualrevenue']?.replaceAll('$', '')?.replaceAll(',', ''),
                    10
                  )
                  const finalRevenue = Number.isNaN(revenue) ? 0 : revenue
                  let redirect = 'https://www.audioeye.com/schedule-meeting/'

                  // TODO: eventually this will change to local pathing instead of absolute
                  if (formLanguage !== 'en') {
                    if (formLanguage === 'de') {
                      redirect = 'https://www.audioeye.com/de/schedule-meeting-de/'
                    } else if (formLanguage === 'fr') {
                      redirect = 'https://www.audioeye.com/fr/schedule-meeting-fr/'
                    } else if (formLanguage === 'es') {
                      redirect = 'https://www.audioeye.com/es/schedule-meeting-es/'
                    } else if (formLanguage === 'it') {
                      redirect = 'https://www.audioeye.com/it/schedule-meeting-it/'
                    }
                  } else if (formData?.agency_or_freelancer__c === 'Yes') {
                    redirect = 'https://www.audioeye.com/schedule-meeting-partner/'
                  } else if (finalRevenue > 5000000) {
                    redirect = 'https://www.audioeye.com/schedule-meeting-enterprise/'
                  }

                  router.push(redirect)
                } else {
                  setIsFormSubmitted(true)
                  setIsSubmitting(false)
                }
              }
            } else {
              setSubmissionError(true)
              setIsSubmitting(false)
            }
          } else {
            trackEvent('API Exception', {
              Label: 'Error with submitting Hubspot form data',
              'Full Exception': result,
            })

            setSubmissionError(true)
            setIsSubmitting(false)
          }
        } catch (error) {
          trackEvent('API Exception', {
            Label: 'Error with submitting Hubspot form data',
            'Full Exception': error,
          })

          setSubmissionError(true)
          setIsSubmitting(false)
        }
      }}
    />
  )
}

export default Form
