import Stack from '../../Layout/Stack/Stack'

const Fieldset = ({ children, ...rest }) => {
  return (
    <Stack
      component="fieldset"
      gap={2}
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems="baseline"
      sx={{ border: 'none !important', padding: '0 !important', margin: '0 !important' }}
      {...rest}
    >
      {children}
    </Stack>
  )
}

export default Fieldset
