'use client'

import Image from 'next/image'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Link, useTheme, useMediaQuery } from '@audioeye-marketing/ui'

// eslint-disable-next-line no-unused-vars -- destructuring so imagePriority is not passed to react component
const Icon = ({ blok, imagePriority, ...rest }) => {
  const theme = useTheme()
  const lowerThanMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const iconSize = lowerThanMediumScreen ? 30 : 40
  const trigger = blok?.trigger && blok?.trigger[0]
  const baseImageProps = {
    src: blok?.icon?.filename,
    width: iconSize,
    height: iconSize,
    alt: blok?.icon?.alt,
    style: trigger
      ? {
          cursor: 'pointer',
        }
      : {},
  }

  if (trigger?.component === 'Modal') {
    return (
      <StoryblokComponent
        blok={trigger}
        trigger={<Image {...storyblokEditable(blok)} {...baseImageProps} {...rest} />}
      />
    )
  } else if (trigger?.component === 'Link') {
    return (
      <Link {...storyblokEditable(blok)} href={trigger?.link?.url} target={trigger?.link?.target} {...rest}>
        <Image {...baseImageProps} />
      </Link>
    )
  }

  return <Image {...storyblokEditable(blok)} {...baseImageProps} {...rest} />
}

export default Icon
