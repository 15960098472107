import { styled } from '@mui/material/styles'
import { colors } from '../../../theme'
import Link from './Link'

export const SkipLink = styled(Link)`
  opacity: 0;
  z-index: -999;
  position: absolute;

  &:focus {
    top: 20px;
    left: 15px;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 150ms;
    text-decoration: none;
    opacity: 1;
    background-color: ${colors.primary500};
    color: white;
    z-index: 9999999999999;
  }
`
