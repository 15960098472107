'use client'

import { render, NODE_HEADING, NODE_PARAGRAPH, MARK_LINK } from 'storyblok-rich-text-react-renderer'
import { Typography, Link } from '@audioeye-marketing/ui'

const renderRichText = (document, options) => {
  return render(document, {
    markResolvers: {
      [MARK_LINK]: (children, { linktype, href, target }) => {
        return (
          <Link href={linktype === 'email' ? `mailto:${href}` : href} target={target}>
            {children}
          </Link>
        )
      },
    },
    nodeResolvers: {
      [NODE_HEADING]: (children, { level }) => (
        <Typography level={`h${level}`} {...options?.NODE_HEADING}>
          {children}
        </Typography>
      ),
      [NODE_PARAGRAPH]: (children) => (
        <Typography level="body-lg" {...options?.NODE_PARAGRAPH}>
          {children}
        </Typography>
      ),
    },
  })
}

export default renderRichText
