import React from 'react'
import { Link as JoyLink } from '@mui/joy'
import { trackEvent } from '../../../lib/track-event'
import { getTextFromChildren } from '../../../lib/get-text-from-children'
import * as Custom from './Link.styles'

const Link = ({ children, ...rest }) => {
  return (
    <JoyLink
      {...rest}
      onClick={() => {
        trackEvent('Link Clicked', {
          'Link Text': getTextFromChildren(children),
          Destination: rest.href,
          Target: rest.target,
        })
      }}
      rel={
        rest.target === '_blank' && !rest.href.startsWith('/') && !rest.href.includes('audioeye.com')
          ? 'noopener noreferrer'
          : undefined
      }
    >
      {children}
      {rest.target === '_blank' && <Custom.ScreenReaderOnly>(opens in a new tab)</Custom.ScreenReaderOnly>}
    </JoyLink>
  )
}

export default Link
