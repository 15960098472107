'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Stack } from '@audioeye-marketing/ui'

const ButtonGroup = ({ blok, backgroundColor, ...rest }) => {
  return (
    <Stack direction="row" spacing={4} {...storyblokEditable(blok)} {...rest}>
      {blok?.buttons?.map((currentButton) => (
        <StoryblokComponent key={currentButton?._uid} blok={currentButton} backgroundColor={backgroundColor} />
      ))}
    </Stack>
  )
}

export default ButtonGroup
