import { Section } from '../../Surfaces/Section'
import colors from '../../../theme/colors'

const Header = ({ children, ...rest }) => {
  return (
    <Section
      {...rest}
      component="header"
      sx={{
        padding: { xs: '1.75rem 2rem', xl: '1.75rem 2.5rem' },
        borderTop: `5px solid ${colors.primary300}`,
      }}
    >
      {children}
    </Section>
  )
}

export default Header
