import { Section } from '../../Surfaces/Section'
import colors from '../../../theme/colors'

const Footer = ({ children, ...rest }) => {
  return (
    <Section {...rest} component="footer" backgroundColor={colors.black}>
      {children}
    </Section>
  )
}

export default Footer
