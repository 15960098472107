'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Stack, Typography, Box } from '@audioeye-marketing/ui'
import renderRichText from '@/lib/render-rich-text'

const TextContent = ({ blok, alignItems, headingLevel, backgroundColor, ...rest }) => {
  return (
    <Box {...storyblokEditable(blok)}>
      <Stack spacing={2} alignItems={alignItems}>
        <Stack spacing={1} {...rest}>
          <Typography level="eyebrow">{blok?.eyebrow}</Typography>
          <Typography level={`h${headingLevel}`}>{blok?.heading}</Typography>
          {renderRichText(blok?.text)}
        </Stack>
        {blok?.cta?.map((currentCta) => (
          <StoryblokComponent key={blok?._uid} blok={currentCta} backgroundColor={backgroundColor} />
        ))}
      </Stack>
    </Box>
  )
}

export default TextContent
