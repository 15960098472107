'use client'

import { storyblokInit, apiPlugin } from '@storyblok/react'
//* Globals
import Frame from '@/components/storyblok/global/Frame'
import Header from '@/components/storyblok/global/Header'
import Footer from '@/components/storyblok/global/Footer'
import Page from '@/components/storyblok/global/Page'
// * Items
import Button from '@/components/storyblok/items/Button'
import ButtonGroup from '@/components/storyblok/items/ButtonGroup'
import Card from '@/components/storyblok/items/Card'
import Form from '@/components/storyblok/items/Form'
import Grid from '@/components/storyblok/items/Grid'
import Icon from '@/components/storyblok/items/Icon'
import Image from '@/components/storyblok/items/Image'
import Modal from '@/components/storyblok/items/Modal'
import ScannerForm from '@/components/storyblok/items/ScannerForm'
import Tab from '@/components/storyblok/items/Tab'
import TextContent from '@/components/storyblok/items/TextContent'
import TextLink from '@/components/storyblok/items/TextLink'
import Video from '@/components/storyblok/items/Video'
// * Sections
import Banner from '@/components/storyblok/sections/Banner'
import BasicContent from '@/components/storyblok/sections/BasicContent'
import ColumnedContent from '@/components/storyblok/sections/ColumnedContent'
import LogoBanner from '@/components/storyblok/sections/LogoBanner'
import Quote from '@/components/storyblok/sections/Quote'
import RichText from '@/components/storyblok/sections/RichText'
import SplitContent from '@/components/storyblok/sections/SplitContent'
import TabbedContent from '@/components/storyblok/sections/TabbedContent'

const components = {
  //* Globals
  Frame,
  Header,
  Footer,
  Page,
  // * Items
  Button,
  'Button Group': ButtonGroup,
  Card,
  Form,
  Grid,
  Icon,
  Image,
  Modal,
  'Scanner Form': ScannerForm,
  Tab,
  'Text Content': TextContent,
  'Text Link': TextLink,
  Video,
  // * Sections
  Banner,
  'Basic Content': BasicContent,
  'Columned Content': ColumnedContent,
  'Logo Banner': LogoBanner,
  Quote,
  'Rich Text': RichText,
  'Split Content': SplitContent,
  'Tabbed Content': TabbedContent,
}

// * Must initialize twice, once server side and once client side. Tutorial found here: https://www.storyblok.com/tp/add-a-headless-cms-to-next-js-13-in-5-minutes
storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_API_TOKEN,
  use: [apiPlugin],
  bridge: process.env.NEXT_PUBLIC_APP_ENV !== 'production',
  apiOptions: {
    region: 'us',
  },
  components,
})

export default function StoryblokProvider({ children }) {
  return children
}
