'use client'

import { storyblokEditable } from '@storyblok/react'
import { Section, Container } from '@audioeye-marketing/ui'
import renderRichText from '@/lib/render-rich-text'
import { translateSectionStyles } from '@/lib/translate-section-styles'

const RichText = ({ blok }) => {
  const sectionStyles = translateSectionStyles(blok?.section_styles[0])

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles}>
      <Container constraint="lg">
        {renderRichText(blok?.text, {
          NODE_PARAGRAPH: {
            marginBottom: '1rem !important',
          },
        })}
      </Container>
    </Section>
  )
}

export default RichText
