import { extendTheme } from '@mui/joy/styles'
import NextLink from 'next/link'
import { forwardRef } from 'react'
import colors from './colors'

const JoyAndNextJSLink = forwardRef(function JoyAndNextJSLink(props, ref) {
  return <NextLink ref={ref} {...props} />
})

const joyTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: colors.primary50,
          100: colors.primary100,
          200: colors.primary200,
          300: colors.primary300,
          400: colors.primary400,
          500: colors.primary500,
          600: colors.primary600,
          700: colors.primary700,
          800: colors.primary800,
          900: colors.primary900,
        },
        secondary: {
          50: colors.secondary50,
          100: colors.secondary100,
          200: colors.secondary200,
          300: colors.secondary300,
          400: colors.secondary400,
          500: colors.secondary500,
          600: colors.secondary600,
          700: colors.secondary700,
          800: colors.secondary900,
          900: colors.secondary900,
          solidBg: 'var(--joy-palette-secondary-300)',
          solidActiveBg: 'var(--joy-palette-secondary-700)',
          solidColor: 'var(--joy-palette-secondary-900)',
          solidDisabledBg: 'var(--joy-palette-neutral-600)',
          solidDisabledColor: 'var(--joy-palette-neutral-200)',
          solidHoverBg: 'var(--joy-palette-secondary-200)',
          outlinedActiveBg: 'var(--joy-palette-secondary-200)',
          outlinedBorder: 'var(--joy-palette-secondary-300)',
          outlinedColor: 'var(--joy-palette-secondary-300)',
          outlinedDisabledBorder: 'var( --joy-palette-neutral-200)',
          outlinedDisabledColor: 'var(--joy-palette-neutral-400)',
          outlinedHoverBg: 'var(--joy-palette-secondary-100)',
          plainActiveBg: 'var(--joy-palette-secondary-600)',
          plainColor: 'var(--joy-palette-secondary-200)',
          plainDisabledColor: 'var(--joy-palette-neutral-400)',
          plainHoverBg: 'var(--joy-palette-secondary-700)',
        },
        light: {
          50: colors.light50,
          100: colors.light100,
          200: colors.light200,
          300: colors.light300,
          400: colors.light400,
          500: colors.light500,
          600: colors.light600,
          700: colors.light700,
          800: colors.light800,
          900: colors.light900,
          solidBg: 'var(--joy-palette-light-50)',
          solidActiveBg: 'var(--joy-palette-light-200)',
          solidColor: 'var(--joy-palette-neutral-900)',
          solidDisabledBg: 'var(--joy-palette-neutral-600)',
          solidDisabledColor: 'var(--joy-palette-neutral-200)',
          solidHoverBg: 'var(--joy-palette-light-100)',
          outlinedActiveBg: 'var(--joy-palette-light-800)',
          outlinedBorder: 'var(--joy-palette-light-200)',
          outlinedColor: 'var(--joy-palette-light-50)',
          outlinedDisabledBorder: 'var( --joy-palette-neutral-200)',
          outlinedDisabledColor: 'var(--joy-palette-neutral-400)',
          outlinedHoverBg: 'var(--joy-palette-light-700)',
          plainActiveBg: 'var(--joy-palette-light-800)',
          plainColor: 'var(--joy-palette-light-50)',
          plainDisabledColor: 'var(--joy-palette-neutral-400)',
          plainHoverBg: 'var(--joy-palette-light-700)',
        },
        black: {
          50: colors.black,
          100: colors.black,
          200: colors.black,
          300: colors.black,
          400: colors.black,
          500: colors.black,
          600: colors.black,
          700: colors.black,
          800: colors.black,
          900: colors.black,
        },
        neutral: {
          50: colors.neutral50,
          100: colors.neutral100,
          200: colors.neutral200,
          300: colors.neutral300,
          400: colors.neutral400,
          500: colors.neutral500,
          600: colors.neutral600,
          700: colors.neutral700,
          800: colors.neutral900,
          900: colors.neutral900,
        },
        information: {
          50: colors.information50,
          100: colors.information100,
          200: colors.information200,
          300: colors.information300,
          400: colors.information400,
          500: colors.information500,
          600: colors.information600,
          700: colors.information700,
          800: colors.information900,
          900: colors.information900,
          solidBg: 'var(--joy-palette-information-400)',
          solidActiveBg: 'var(--joy-palette-information-500)',
          outlinedBorder: 'var(--joy-palette-information-500)',
          outlinedColor: 'var(--joy-palette-information-700)',
          outlinedActiveBg: 'var(--joy-palette-information-100)',
          softColor: 'var(--joy-palette-information-800)',
          softBg: 'var(--joy-palette-information-200)',
          softActiveBg: 'var(--joy-palette-information-300)',
          plainColor: 'var(--joy-palette-information-700)',
          plainActiveBg: 'var(--joy-palette-information-100)',
        },
        danger: {
          50: colors.danger50,
          100: colors.danger100,
          200: colors.danger200,
          300: colors.danger300,
          400: colors.danger400,
          500: colors.danger500,
          600: colors.danger600,
          700: colors.danger700,
          800: colors.danger900,
          900: colors.danger900,
        },
        success: {
          50: colors.success50,
          100: colors.success100,
          200: colors.success200,
          300: colors.success300,
          400: colors.success400,
          500: colors.success500,
          600: colors.success600,
          700: colors.success700,
          800: colors.success900,
          900: colors.success900,
        },
        warning: {
          50: colors.warning50,
          100: colors.warning100,
          200: colors.warning200,
          300: colors.warning300,
          400: colors.warning400,
          500: colors.warning500,
          600: colors.warning600,
          700: colors.warning700,
          800: colors.warning900,
          900: colors.warning900,
        },
      },
    },
  },
  typography: {
    h1: {
      fontSize: '3rem',
      color: 'inherit',
      marginBottom: '1rem !important',
    },
    h2: {
      color: 'inherit',
      marginBottom: '1rem !important',
    },
    h3: {
      color: 'inherit',
      marginBottom: '.5rem !important',
    },
    h4: {
      color: 'inherit',
      marginBottom: '.5rem !important',
    },
    'title-lg': {
      color: 'inherit',
    },
    'title-md': {
      color: 'inherit',
    },
    'title-sm': {
      color: 'inherit',
    },
    'body-lg': {
      color: 'inherit',
    },
    'body-md': {
      color: 'inherit',
    },
    'body-sm': {
      color: 'inherit',
    },
    'body-xs': {
      lineHeight: 'var(--joy-lineHeight-sm)',
      fontWeight: 'var(--joy-fontWeight-lg)',
      color: 'inherit',
    },
    eyebrow: {
      fontSize: 'var(--joy-fontSize-sm)',
      lineHeight: 'var(--joy-lineHeight-sm)',
      fontWeight: '500',
      color: 'inherit',
      fontFamily: 'var(--font-dm-mono)',
      textTransform: 'uppercase',
    },
  },
  fontFamily: {
    display: 'var(--font-schibsted-grotesk)', // applies to `h1`–`h4`
    body: 'var(--font-schibsted-grotesk)', // applies to `title-*` and `body-*`
  },
  components: {
    JoyRadioGroup: {
      styleOverrides: {
        root: {
          margin: '0',
        },
      },
    },
    JoyLink: {
      defaultProps: {
        component: JoyAndNextJSLink,
      },
    },
    JoyButton: {
      styleOverrides: {
        root: {
          width: 'fit-content',
        },
      },
    },
  },
})

export default joyTheme
