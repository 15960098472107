'use client'

import { useState } from 'react'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { SkipLink } from '@audioeye-marketing/ui'
import Frame from '@/components/storyblok/global/Frame'
import PasswordProtectedPage from '@/components/PasswordProtectedPage'

const Page = ({ blok, rels }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const numberOfBeforeContentSections = blok?.frame?.content?.before_content?.length
  const hasBeforeContent = numberOfBeforeContentSections > 0

  if (blok?.password && !isAuthenticated) {
    return <PasswordProtectedPage cmsPassword={blok?.password} setIsAuthenticated={setIsAuthenticated} />
  }

  return (
    <div {...storyblokEditable(blok)}>
      <SkipLink />
      <Frame blok={blok?.frame?.content} rels={rels}>
        {blok?.body?.map((currentSection, index) => (
          <StoryblokComponent
            blok={currentSection}
            key={currentSection?._uid}
            headingLevel={index === 0 && !hasBeforeContent ? 1 : 2}
            imagePriority={numberOfBeforeContentSections + index === 0 || numberOfBeforeContentSections + index === 1}
          />
        ))}
      </Frame>
    </div>
  )
}

export default Page
