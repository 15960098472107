'use client'

import { useState } from 'react'
import { useRouter } from 'next/navigation'
import { storyblokEditable } from '@storyblok/react'
import {
  Card,
  Input,
  Form,
  FormControl,
  FormLabel,
  Button,
  Stack,
  trackEvent,
  Box,
  Typography,
  FormHelperText,
  useMediaQuery,
  useTheme,
  getLanguageTranslation,
} from '@audioeye-marketing/ui'
import { useAppContext } from '@/providers/App.jsx'

const ScannerForm = ({ blok, ...rest }) => {
  const [websiteUrl, setWebsiteUrl] = useState('')
  const [validationError, setValidationError] = useState('')
  const router = useRouter()
  const { currentLanguage } = useAppContext()
  const theme = useTheme()
  const lowerThanMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleSubmit = (e) => {
    e.preventDefault()

    const regex = /^(?<protocol>(?:ftp|http|https):\/\/)?(?<domain>(?:[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)+\.[a-zA-Z]{2,}))$/i

    if (websiteUrl.match(regex)) {
      window.sessionStorage.setItem('scan_url', websiteUrl)
      const url = encodeURI(
        `https://www.audioeye.com/scan/results/?url=${websiteUrl}&partner=false&lang=${currentLanguage}`
      )

      trackEvent('Site Scan URL Submitted', {
        'Site URL': websiteUrl,
        'BOIA Scan': false,
      })

      router.push(url)
    } else setValidationError(getLanguageTranslation('validWebsiteAddress', currentLanguage))
  }

  const Wrapper = blok.card ? Card : Box

  const additionalProps = blok.card ? { size: 'lg' } : { sx: { pt: 4 } }

  const combinedSx = {
    ...rest.sx,
    ...additionalProps.sx,
    width: '100%',
    maxWidth: '500px',
    backgroundColor: 'white',
    border: 'none',
    marginTop: '40px !important',
  }

  const combinedProps = {
    ...rest,
    ...additionalProps,
    sx: combinedSx,
  }

  return (
    <Wrapper {...combinedProps} {...storyblokEditable(blok)}>
      <Form name="Site Scan URL" onSubmit={handleSubmit}>
        <FormControl error={Boolean(validationError)}>
          <Stack spacing={2}>
            <FormLabel>
              <Typography>
                <strong>{blok?.heading}</strong>
              </Typography>
            </FormLabel>
            <Box>
              {!lowerThanMediumScreen ? (
                <Input
                  sx={{ '--Input-decoratorChildHeight': '45px' }}
                  name="website-url-desktop"
                  placeholder="www.yourwebsite.com"
                  type="text"
                  required
                  value={websiteUrl}
                  onChange={(e) => setWebsiteUrl(e.target.value)}
                  endDecorator={
                    <Button
                      variant="solid"
                      size="lg"
                      color="primary"
                      type="submit"
                      sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    >
                      {blok?.button_text || 'Free Scan'}
                    </Button>
                  }
                />
              ) : (
                <>
                  <Input
                    sx={{ height: '45px', mb: 2 }}
                    name="website-url-mobile"
                    placeholder="www.yourwebsite.com"
                    type="text"
                    required
                    value={websiteUrl}
                    onChange={(e) => setWebsiteUrl(e.target.value)}
                  />
                  <Button variant="solid" size="lg" color="primary" type="submit" sx={{ width: '100%' }}>
                    {blok?.button_text || 'Free Scan'}
                  </Button>
                </>
              )}
            </Box>
            {Boolean(validationError) && <FormHelperText>{validationError}</FormHelperText>}
          </Stack>
        </FormControl>
      </Form>
    </Wrapper>
  )
}

export default ScannerForm
