'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Link } from '@audioeye-marketing/ui'

// eslint-disable-next-line no-unused-vars -- destructuring so fullWidth is not passed to react component
const TextLink = ({ blok, fullWidth, ...rest }) => {
  const trigger = blok?.trigger && blok?.trigger[0]
  const baseLinkProps = {
    ...storyblokEditable(blok),
    size: 'lg',
    ...rest,
  }

  if (trigger?.component === 'Modal') {
    return (
      <StoryblokComponent
        blok={trigger}
        trigger={
          <Link {...baseLinkProps}>
            <strong>{blok?.text}</strong>
          </Link>
        }
      />
    )
  } else if (trigger?.component === 'Link') {
    return (
      <Link
        {...baseLinkProps}
        href={trigger?.link?.linktype === 'story' ? `/${trigger?.link?.cached_url}` : trigger?.link?.cached_url || '/'}
        target={trigger?.link?.target}
        // Necessary for CookieYes. All links will be considered a CookieYes Link if the url is #
        className={Boolean(trigger?.link?.cached_url === '#') && 'cky-banner-element'}
      >
        <strong>{blok?.text}</strong>
      </Link>
    )
  }

  return <Link {...baseLinkProps}>{blok?.text}</Link>
}

export default TextLink
