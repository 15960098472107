'use client'

import { useFormContext } from 'react-hook-form'
import Button from '../../Button/Button'
import FormControl from '../FormControl'
import FormHelperText from '../FormHelperText'
import Stack from '../../../Layout/Stack/Stack'
import Box from '../../../Layout/Box/Box'
import Typography from '../../../DataDisplay/Typography/Typography'
import { useFormContext as useUIFormContext } from '../FormProvider'
import Link from '../../../Navigation/Link/Link'
import HubspotFormFieldGroups from './HubspotFormFieldGroups'
import parseHtmlString from './utils/parse-html-string'
import getLanguageTranslation from './utils/get-language-translation'

const HubspotFormContent = ({
  formName,
  fieldGroups,
  legalConsentOptions,
  submitButtonText = 'Submit',
  isSubmitting,
  isFormSubmitted,
}) => {
  const privacyText = legalConsentOptions?.privacyText

  const {
    formState: { errors },
    getValues,
  } = useFormContext()

  const formFieldValues = getValues()
  const { language } = useUIFormContext()

  return isFormSubmitted ? (
    <Box sx={{ textAlign: 'center' }}>
      <Typography level="h2">Thanks for reaching out!</Typography>
      <Typography level="body-lg">Someone from the AudioEye team will contact you shortly.</Typography>
    </Box>
  ) : (
    <Stack gap={2} direction="column" sx={{ mb: 2, p: 1 }}>
      {formName === 'Free Trial Form' && (
        <Typography level="h2" color="primary" textAlign="center">
          {getLanguageTranslation('trialHeading', language)}
        </Typography>
      )}
      <HubspotFormFieldGroups fieldGroups={fieldGroups} />
      {Boolean(privacyText) && parseHtmlString(privacyText)}
      {Object.keys(errors).length > 0 && (
        <FormControl error>
          <FormHelperText>{getLanguageTranslation('completeAllRequiredFields', language)}</FormHelperText>
        </FormControl>
      )}
      {Boolean(submitButtonText) && (
        <Button type="submit" loading={isSubmitting} size="lg" fullWidth>
          {submitButtonText}
        </Button>
      )}
      {formName === 'Free Trial Form' && formFieldValues?.agency_or_freelancer__c === 'Yes' ? (
        <Typography level="body-xs">
          {getLanguageTranslation('createAccount', language)}{' '}
          <Link target="_blank" href={getLanguageTranslation('agencyTerms', language).href}>
            <strong>{getLanguageTranslation('agencyTerms', language).text}</strong>
          </Link>
          {', '}
          <Link target="_blank" href={getLanguageTranslation('termsOfService', language).href}>
            <strong>{getLanguageTranslation('termsOfService', language).text}</strong>
          </Link>{' '}
          {getLanguageTranslation('and', language)}{' '}
          <Link target="_blank" href={getLanguageTranslation('privacyPolicy', language).href}>
            <strong>{getLanguageTranslation('privacyPolicy', language).text}</strong>
          </Link>
        </Typography>
      ) : formName === 'Free Trial Form' ? (
        <Typography level="body-xs">
          {getLanguageTranslation('createAccount', language)}{' '}
          <Link target="_blank" href={getLanguageTranslation('termsOfService', language).href}>
            <strong>{getLanguageTranslation('termsOfService', language).text}</strong>
          </Link>{' '}
          {getLanguageTranslation('and', language)}{' '}
          <Link target="_blank" href={getLanguageTranslation('privacyPolicy', language).href}>
            <strong>{getLanguageTranslation('privacyPolicy', language).text}</strong>
          </Link>
        </Typography>
      ) : null}
      {language !== 'en' && (
        <Typography level="body-xs">{getLanguageTranslation('emailCommunications', language)}</Typography>
      )}
    </Stack>
  )
}

export default HubspotFormContent
