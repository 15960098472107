'use client'

import { useEffect } from 'react'
import { usePathname } from 'next/navigation'

const HubspotTracker = () => {
  const pathname = usePathname()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      /* eslint-disable -- matches to Hubspot's documentation */
      const _hsq = (window._hsq = window._hsq || [])

      // Hard to debug locally since reactStrictMode will cause two renders at the start
      _hsq.push(['setPath', pathname])
      _hsq.push(['trackPageView'])
    }
  }, [pathname])

  return null
}

export default HubspotTracker
