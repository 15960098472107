/**
 * @param {string} padding - a string that is either sm, md, or lg to represent the amount of padding needed
 * @param {boolean} collapseTopPadding - determines whether to collapse the top padding to zero
 * @param {boolean} collapseBottomPadding - determines whether to collapse the bottom padding to zero
 * @param {boolean} doubleBottomPadding - determines whether to double the bottom padding
 * @return {string} - css string representing the padding to use for the section
 */
export const determinePadding = ({ padding, collapseTopPadding, collapseBottomPadding, doubleBottomPadding }) => {
  let topPadding
  let bottomPadding

  if (padding === 'sm') {
    topPadding = '2.5rem'
    bottomPadding = doubleBottomPadding ? '5rem' : '2.5rem'
  } else if (padding === 'md') {
    topPadding = '4rem'
    bottomPadding = doubleBottomPadding ? '8rem' : '4rem'
  } else if (padding === 'lg') {
    topPadding = '5.5rem'
    bottomPadding = doubleBottomPadding ? '11rem' : '5.5rem'
  } else {
    topPadding = '5.5rem'
    bottomPadding = doubleBottomPadding ? '11rem' : '5.5rem'
  }

  if (collapseTopPadding) {
    topPadding = 0
  }

  if (collapseBottomPadding) {
    bottomPadding = 0
  }

  return `${topPadding} 2rem ${bottomPadding} 2rem`
}
