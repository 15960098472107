'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Section, Container, Stack, Typography, Card } from '@audioeye-marketing/ui'

const LogoBanner = ({ blok, imagePriority }) => {
  return (
    <Section
      {...storyblokEditable(blok)}
      collapseTopPadding
      collapseBottomPadding
      component="section"
      textAlign="center"
      floatSection
    >
      <Container constraint="lg">
        <Card
          size="lg"
          sx={{
            backgroundColor: 'white',
            border: 'none',
            boxShadow: '0px 0px 14px 3px rgba(102, 99, 105, 0.18)',
          }}
        >
          <Stack alignItems="center" justifyContent="center">
            <Typography level="title-lg" sx={{ mb: 2, textAlign: 'center' }}>
              {blok?.heading}
            </Typography>
            {blok?.grid?.map((currentGrid) => (
              <StoryblokComponent key={currentGrid?._uid} blok={currentGrid} imagePriority={imagePriority} />
            ))}
          </Stack>
        </Card>
      </Container>
    </Section>
  )
}

export default LogoBanner
