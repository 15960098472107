'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import {
  Section,
  Container,
  Tabs,
  TabList,
  Tab,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
  tabClasses,
} from '@audioeye-marketing/ui'
import { translateSectionStyles } from '@/lib/translate-section-styles'

const TabbedContent = ({ blok, headingLevel, imagePriority }) => {
  const sectionStyles = translateSectionStyles(blok?.section_styles[0])
  const theme = useTheme()
  const lowerThanMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles} textAlign="center">
      <Container constraint="sm">
        {blok?.text_content?.map((currentTextContent) => (
          <StoryblokComponent
            key={currentTextContent?._uid}
            blok={currentTextContent}
            backgroundColor={sectionStyles?.backgroundColor}
            headingLevel={headingLevel}
          />
        ))}
      </Container>
      <Container constraint="lg">
        <Tabs
          size="lg"
          orientation={lowerThanMediumScreen ? 'horizontal' : 'vertical'}
          sx={{ marginTop: '40px', backgroundColor: `${sectionStyles?.backgroundColor}` }}
        >
          <TabList
            disableUnderline
            sx={{
              minWidth: '300px',
              alignSelf: { xs: 'center', md: 'flex-start' },
              [`&& .${tabClasses?.root}`]: {
                bgcolor: 'transparent',
                '&:hover': {
                  bgcolor: 'transparent',
                },
                [`&.${tabClasses.selected}`]: {
                  color: 'primary.plainColor',
                  fontWeight: 'bold',
                },
              },
            }}
          >
            <Stack spacing={2}>
              <Typography level="title-lg" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                <strong>{blok?.tab_list_heading}</strong>
              </Typography>
              <Stack
                direction={{ xs: 'row', md: 'column' }}
                sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
              >
                {blok?.tabs?.map((currentTab) => {
                  return (
                    <Tab
                      key={currentTab?._uid}
                      color="primary"
                      variant="plain"
                      indicatorPlacement={{ xs: 'bottom', md: 'left' }}
                      sx={{ textAlign: { xs: 'center', md: 'left' } }}
                    >
                      {currentTab?.tab_heading}
                    </Tab>
                  )
                })}
              </Stack>
            </Stack>
          </TabList>
          {blok?.tabs?.map((currentTab, index) => {
            return (
              <StoryblokComponent
                key={currentTab?._uid}
                blok={currentTab}
                value={index}
                headingLevel={headingLevel}
                backgroundColor={sectionStyles?.backgroundColor}
                imagePriority={imagePriority}
              />
            )
          })}
        </Tabs>
      </Container>
    </Section>
  )
}

export default TabbedContent
