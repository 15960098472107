import Box from '../Box/Box'

const Container = ({ children, constraint, ...rest }) => {
  return (
    <Box
      {...rest}
      sx={{
        maxWidth: constraint === 'sm' ? '700px' : constraint === 'md' ? '864px' : '1280px',
        marginLeft: 'auto !important',
        marginRight: 'auto !important',
        position: 'relative',
        zIndex: 1,
      }}
    >
      {children}
    </Box>
  )
}

export default Container
