'use client'

import NextImage from 'next/image'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Link, AspectRatio, Box } from '@audioeye-marketing/ui'
import { useTheme } from '@mui/joy/styles'

const ImageWrapper = ({ imageDimensions, children, theme, ...rest }) => {
  return (
    <Box sx={{ width: '100%', maxWidth: `${imageDimensions?.maxWidth}px` }}>
      <AspectRatio
        variant="plain"
        ratio={`${imageDimensions?.width}/${imageDimensions?.height}`}
        sx={{
          position: 'relative',
          width: '100%',
          '&:focus': {
            outline: `${theme?.vars?.focus?.thickness} solid ${theme?.vars?.palette?.primary['500']}`,
            borderRadius: `${theme?.vars?.radius?.sm}`,
          },
        }}
        {...rest}
      >
        {children}
      </AspectRatio>
    </Box>
  )
}

// eslint-disable-next-line no-unused-vars -- destructuring so backgroundColor is not passed to JoyCard
const Image = ({ blok, maxWidth, backgroundColor, headingLevel, imagePriority, ...rest }) => {
  const theme = useTheme()
  const trigger = blok.trigger && blok.trigger[0]

  // * https://www.storyblok.com/faq/image-dimensions-assets-js
  const originalWidth = parseInt(blok?.image?.filename?.split('/')[5]?.split('x')[0])
  const originalHeight = parseInt(blok?.image?.filename?.split('/')[5]?.split('x')[1])
  const calculatedMaxWidth = maxWidth ? maxWidth : originalWidth < 600 ? originalWidth : 600

  const imageDimensions = {
    width: originalWidth,
    height: originalHeight,
    maxWidth: calculatedMaxWidth,
  }

  if (calculatedMaxWidth && originalWidth > calculatedMaxWidth) {
    const aspectRatio = originalHeight / originalWidth
    imageDimensions.width = calculatedMaxWidth
    imageDimensions.height = Math.round(calculatedMaxWidth * aspectRatio)
  }

  if (!blok?.image?.filename) {
    return null
  }

  const FinalImage = (
    <NextImage
      src={blok?.image?.filename}
      alt={blok?.image?.alt}
      style={trigger ? { objectFit: 'contain', cursor: 'pointer' } : { objectFit: 'contain' }}
      width={imageDimensions.width}
      height={imageDimensions.height}
      priority={imagePriority}
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw"
      // * https://www.storyblok.com/docs/image-service
      loader={({ src }) => `${src}/m/${imageDimensions.width}x0/filters:quality(100)`}
    />
  )

  if (trigger?.component === 'Modal') {
    return (
      <StoryblokComponent
        blok={trigger}
        trigger={
          <ImageWrapper {...storyblokEditable(blok)} imageDimensions={imageDimensions} {...rest} theme={theme}>
            {FinalImage}
          </ImageWrapper>
        }
      />
    )
  } else if (trigger?.component === 'Link') {
    return (
      <Link
        {...storyblokEditable(blok)}
        href={trigger?.link?.url}
        target={trigger?.link?.target}
        {...rest}
        theme={theme}
      >
        <ImageWrapper imageDimensions={imageDimensions}>{FinalImage}</ImageWrapper>
      </Link>
    )
  }

  return (
    <ImageWrapper {...storyblokEditable(blok)} imageDimensions={imageDimensions} {...rest} theme={theme}>
      {FinalImage}
    </ImageWrapper>
  )
}

export default Image
