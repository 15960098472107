'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Section, Container, Typography, Stack } from '@audioeye-marketing/ui'
import renderRichText from '@/lib/render-rich-text'
import { translateSectionStyles } from '@/lib/translate-section-styles'

const Quote = ({ blok, headingLevel, imagePriority }) => {
  const sectionStyles = translateSectionStyles(blok.section_styles[0])

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles}>
      <Container constraint="sm">
        {blok?.text_content?.map((currentTextContent) => (
          <StoryblokComponent
            key={currentTextContent?._uid}
            blok={currentTextContent}
            backgroundColor={sectionStyles?.backgroundColor}
            headingLevel={headingLevel}
          />
        ))}
      </Container>
      <Container constraint="md">
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
          {blok?.image?.map((currentImage) => (
            <StoryblokComponent
              key={currentImage?._uid}
              blok={currentImage}
              backgroundColor={sectionStyles?.backgroundColor}
              headingLevel={headingLevel}
              maxWidth={250}
              imagePriority={imagePriority}
            />
          ))}

          <Stack spacing={1} justifyContent="center">
            {renderRichText(blok?.quote)}
            <Typography>
              <strong>{blok?.name}</strong>
            </Typography>
            <Typography>{blok?.job_title}</Typography>
          </Stack>
        </Stack>
      </Container>
    </Section>
  )
}

export default Quote
