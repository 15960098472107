'use client'

/**
 * @param {?} item - either a string or an object of the storyblok story
 * @param {array} rels - array of all resolved stories that came from the storyblok api
 * @return {object} - the content of the storyblok story
 */
export const getResolvedStoryblokStory = (item, rels) => {
  if (typeof item === 'string') {
    let content = null

    rels?.forEach((rel) => {
      if (rel.uuid === item) {
        content = rel?.content
      }
    })

    return content
  }

  return item?.content
}
