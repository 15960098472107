'use client'

import { useEffect } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import { trackEvent } from '@audioeye-marketing/ui'

const UtmParamsTracker = () => {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const googleAdsClickId = searchParams.get('gclid') || ''

      const utmParams = {
        utm_campaign: searchParams.get('utm_campaign') || '',
        utm_content: searchParams.get('utm_content') || '',
        utm_medium: searchParams.get('utm_medium') || '',
        utm_source: searchParams.get('utm_source') || '',
        utm_term: searchParams.get('utm_term') || '',
      }

      if (!localStorage.getItem('google_ads_gclid')) {
        localStorage.setItem('google_ads_gclid', JSON.stringify(googleAdsClickId))
      }

      if (!localStorage.getItem('utm_params')) {
        localStorage.setItem('utm_params', JSON.stringify(utmParams))
      }

      if (googleAdsClickId) {
        localStorage.setItem('google_ads_gclid', JSON.stringify(googleAdsClickId))
      }

      if (
        utmParams.utm_campaign ||
        utmParams.utm_content ||
        utmParams.utm_medium ||
        utmParams.utm_source ||
        utmParams.utm_term
      ) {
        if (!localStorage.getItem('utm_params_initial')) {
          localStorage.setItem('utm_params_initial', JSON.stringify(utmParams))
        }

        localStorage.setItem('utm_params', JSON.stringify(utmParams))
      }

      const utmParamsCurrent = JSON.parse(localStorage.getItem('utm_params'))
      const utmParamsInitial = JSON.parse(localStorage.getItem('utm_params_initial'))

      trackEvent(`Page Viewed`, {
        'Initial UTM Campaign': utmParamsInitial ? utmParamsInitial.utm_campaign : '',
        'Initial UTM Content': utmParamsInitial ? utmParamsInitial.utm_content : '',
        'Initial UTM Medium': utmParamsInitial ? utmParamsInitial.utm_medium : '',
        'Initial UTM Source': utmParamsInitial ? utmParamsInitial.utm_source : '',
        'Initial UTM Term': utmParamsInitial ? utmParamsInitial.utm_term : '',
        'Latest UTM Campaign': utmParamsCurrent ? utmParamsCurrent.utm_campaign : '',
        'Latest UTM Content': utmParamsCurrent ? utmParamsCurrent.utm_content : '',
        'Latest UTM Medium': utmParamsCurrent ? utmParamsCurrent.utm_medium : '',
        'Latest UTM Source': utmParamsCurrent ? utmParamsCurrent.utm_source : '',
        'Latest UTM Term': utmParamsCurrent ? utmParamsCurrent.utm_term : '',
      })
    }
  }, [pathname, searchParams])

  return null
}

export default UtmParamsTracker
