import { Box as JoyBox } from '@mui/joy'
import { colors } from '../../../theme'
import { determinePadding } from '../../../lib/determine-padding'
import { determineTextColor } from '../../../lib/determine-text-color'

const Section = ({
  children,
  backgroundColor,
  padding,
  collapseTopPadding,
  collapseBottomPadding,
  doubleBottomPadding,
  textAlign,
  component,
  floatSection,
  ...rest
}) => {
  const calculatedPadding = determinePadding({
    padding,
    collapseTopPadding,
    collapseBottomPadding,
    doubleBottomPadding,
  })

  const calculatedTextColor = determineTextColor({ backgroundColor })

  const sxProps = {
    padding: calculatedPadding,
    backgroundColor: !floatSection ? backgroundColor || colors.white : null,
    color: `${calculatedTextColor.body} !important`,
    textAlign: textAlign || 'left',
    '.MuiTypography-eyebrow': {
      color: `${calculatedTextColor.eyebrow} !important`,
    },
  }

  if (floatSection) {
    sxProps.marginTop = '-70px'
  }

  return (
    <JoyBox backgroundColor={backgroundColor} component={component || 'section'}>
      <JoyBox sx={sxProps} {...rest}>
        {children}
      </JoyBox>
    </JoyBox>
  )
}

export default Section
