'use client'

/**
 * @param {object} cookieName - name of the cookie to get the value for
 * @return {string} - the value of the cookie
 */
export const getBrowserCookie = (cookieName) => {
  if (typeof document === 'undefined') {
    return
  }

  const cookies = document.cookie
    .split(';')
    .map((c) => c.trim().split('=').map(decodeURIComponent))
    .reduce((acc, cookie) => {
      const [cookieName, value] = cookie
      try {
        acc[cookieName] = JSON.parse(value)
      } catch (e) {
        acc[cookieName] = value
      }
      return acc
    }, {})

  return cookies[cookieName]
}
