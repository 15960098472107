'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Grid as UIGrid } from '@audioeye-marketing/ui'

const Grid = ({ blok, backgroundColor, imagePriority, ...rest }) => {
  const baseGridSx = {
    display: 'flex',
    justifyContent: 'center',
  }

  if (blok?.columns === 'none') {
    // Takes the width of the md Section container minus the padding of spacing={4}
    baseGridSx.width = `${864 / blok?.items?.length - 16}px`
    baseGridSx.display = 'flex'
    baseGridSx.justifyContent = 'center'
  }

  const baseGridProps = blok?.columns !== 'none' ? { xs: 12, md: 12 / blok?.columns } : {}

  return (
    <UIGrid {...storyblokEditable(blok)} container spacing={4} justifyContent={blok?.justify_content} {...rest}>
      {blok?.items?.map((currentItem, index) => {
        const remainingItems = blok?.items?.length % blok?.columns

        if (blok?.bento_box && remainingItems > 0 && index >= blok?.items?.length - remainingItems) {
          baseGridProps.md = 12 / remainingItems
        }

        return (
          <UIGrid key={currentItem?._uid} sx={baseGridSx} {...baseGridProps}>
            <StoryblokComponent blok={currentItem} backgroundColor={backgroundColor} imagePriority={imagePriority} />
          </UIGrid>
        )
      })}
    </UIGrid>
  )
}

export default Grid
