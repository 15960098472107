'use client'

import Typography from '../../DataDisplay/Typography/Typography'
import * as Custom from './SkipLink.styles'

const SkipLink = () => {
  return (
    <Custom.SkipLink href="#content" rel="noopener noreferrer">
      <Typography>Skip to main content</Typography>
    </Custom.SkipLink>
  )
}

export default SkipLink
