import invalidEmails from './invalid-emails'
import getLanguageTranslations from './get-language-translation'

/**
 * @param {object} content - object containing the content of the storyblok page content type
 * @param {object} params - object containing the params from the nextjs router
 * @param {string} lang - string containing the lang determined in middleware
 * @return {object} - object containing the metadata for the page
 */
const fieldValidations = (field, language) => {
  return {
    required: field?.required ? getLanguageTranslations('fieldRequired', language) : false,
    validate:
      field?.fieldType === 'email'
        ? (email) => {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
              return getLanguageTranslations('invalidEmailFormat', language)
            }

            // Blocked email domains come from the Hubspot form configuration
            const forbiddenDomains = field?.validation?.blockedEmailDomains
            const domain = email.split('@')[1].toLowerCase()

            if (
              forbiddenDomains.includes(domain) ||
              // If Hubspot form has the option to block emails from the default list
              (field?.validation?.useDefaultBlockList && invalidEmails.has(domain))
            ) {
              return getLanguageTranslations('emailsFromDomainNotAllowed', language).replace('{domain}', domain)
            }

            return true
          }
        : field?.fieldType === 'phone'
          ? (phone) => {
              if (field?.validation) {
                if (
                  phone?.length < field.validation.minAllowedDigits ||
                  phone?.length > field.validation.maxAllowedDigits
                ) {
                  return getLanguageTranslations('numberNotInRange', language)
                }
              }

              if (!field?.required && phone?.length === 0) {
                return true
              }

              if (!/^[0-9]+$/.test(phone)) {
                return getLanguageTranslations('numbersOnly', language)
              }

              return true
            }
          : field?.fieldType === 'number'
            ? (number) => {
                if (field?.validation) {
                  if (number > field.validation.maxAllowedDigits) {
                    return getLanguageTranslations('numberLessThan', language).replace(
                      '{number}',
                      field.validation.maxAllowedDigits
                    )
                  }

                  if (number < field.validation.minAllowedDigits) {
                    return getLanguageTranslations('numberGreaterThan', language).replace(
                      '{number}',
                      field.validation.minAllowedDigits
                    )
                  }
                }

                return true
              }
            : field?.name === 'password'
              ? (password) => {
                  if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+{}[\]:;"'<>,.?~`-]{8,}$/.test(password)) {
                    return getLanguageTranslations('passwordNotValid', language)
                  }

                  return true
                }
              : field?.name === 'website'
                ? (website) => {
                    if (!/^(?:https?:\/\/)?(?:www\.)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/.*)?$/.test(website)) {
                      return getLanguageTranslations('invalidWebsite', language)
                    }

                    return true
                  }
                : undefined,
  }
}

export default fieldValidations
