import colors from '../theme/colors'

/**
 * @param {string} backgroundColor - string representing the background color
 * @return {object} - string representing the text color for the body and eyebrow
 */
export const determineTextColor = ({ backgroundColor }) => {
  switch (backgroundColor) {
    case '#000000':
      return {
        eyebrow: colors.secondary200,
        link: colors.secondary300,
        body: colors.white,
      }
    case '#260449':
      return {
        eyebrow: colors.secondary200,
        link: colors.secondary300,
        body: colors.white,
      }
    case '#002C2E':
      return {
        eyebrow: colors.white,
        link: colors.secondary300,
        body: colors.white,
      }
    case '#F7F9FC': {
      return {
        eyebrow: colors.neutral600,
        link: colors.primary500,
        body: colors.black,
      }
    }
    case '#FFFFFF': {
      return {
        eyebrow: colors.neutral600,
        link: colors.primary500,
        body: colors.black,
      }
    }
    // Should mimic #FFFFFF
    default:
      return {
        eyebrow: colors.neutral600,
        link: colors.primary500,
        body: colors.black,
      }
  }
}
