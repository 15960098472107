'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Button as UIButton, LinkButton } from '@audioeye-marketing/ui'
import { determineButtonColor } from '@/lib/determine-button-color'
import { determineButtonVariant } from '@/lib/determine-button-variant'

const Button = ({ blok, backgroundColor, ...rest }) => {
  const color = determineButtonColor({ backgroundColor })
  const variant = determineButtonVariant({ variant: blok?.variant })
  const trigger = blok?.trigger && blok?.trigger[0]

  if (trigger?.component === 'Modal') {
    return (
      <StoryblokComponent
        blok={trigger}
        trigger={
          <UIButton {...storyblokEditable(blok)} color={color} size="lg" variant={variant} {...rest}>
            {blok?.text}
          </UIButton>
        }
      />
    )
  } else if (trigger?.component === 'Link') {
    return (
      <LinkButton
        {...storyblokEditable(blok)}
        color={color}
        size="lg"
        variant={variant}
        href={trigger?.link?.cached_url}
        target={trigger?.link?.target}
        {...rest}
      >
        {blok?.text}
      </LinkButton>
    )
  }

  return (
    <UIButton {...storyblokEditable(blok)} color={color} size="lg" variant={variant} {...rest}>
      {blok?.text}
    </UIButton>
  )
}

export default Button
