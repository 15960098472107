'use client'

/**
 * @param {string} backgroundColor - string representing the background color
 * @return {object} - string representing the button color
 */
export const determineButtonColor = ({ backgroundColor }) => {
  switch (backgroundColor) {
    case '#000000':
      return 'light'
    case '#260449':
      return 'light'
    case '#002C2E':
      return 'light'
    case '#F7F9FC':
      return 'primary'
    case '#FFFFFF':
      return 'primary'
    default:
      return 'primary'
  }
}
