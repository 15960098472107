const colors = {
  primary50: '#EEEBFF',
  primary100: '#E5E0FF',
  primary200: '#D4CCFF',
  primary300: '#C0B3FF',
  primary400: '#9D80FF',
  primary500: '#8451FB',
  primary600: '#6317CF',
  primary700: '#4F09AA',
  primary800: '#3B037C',
  primary900: '#260449',
  secondary50: '#E2FDFC',
  secondary100: '#C5F7F4',
  secondary200: '#9AEAE6',
  secondary300: '#64D3CE',
  secondary400: '#2DB4AD',
  secondary500: '#038280',
  secondary600: '#01595A',
  secondary700: '#014142',
  secondary800: '#002C2E',
  secondary900: '#001D1E',
  neutral50: '#F7F9FC',
  neutral100: '#EAEEF6',
  neutral200: '#D7DDEA',
  neutral300: '#BDC5D6',
  neutral400: '#9FA8BC',
  neutral500: '#6B758A',
  neutral600: '#4C5567',
  neutral700: '#343C4C',
  neutral800: '#212835',
  neutral900: '#191E29',
  information50: '#F0F7FF',
  information100: '#DBEDFF',
  information200: '#BDDEFF',
  information300: '#99CAFF',
  information400: '#75B3FF',
  information500: '#2469FF',
  information600: '#0452D7',
  information700: '#003EA8',
  information800: '#002D70',
  information900: '#001E3D',
  danger50: '#FFF0F0',
  danger100: '#FFD7D6',
  danger200: '#FFB4B2',
  danger300: '#FD9390',
  danger400: '#FC716E',
  danger500: '#E02D2D',
  danger600: '#B80F0F',
  danger700: '#930B0B',
  danger800: '#6C0404',
  danger900: '#240505',
  success50: '#DAFBE8',
  success100: '#B5F2D0',
  success200: '#8CE3B2',
  success300: '#56C286',
  success400: '#349D65',
  success500: '#22864F',
  success600: '#1E7143',
  success700: '#185935',
  success800: '#124026',
  success900: '#0B2818',
  warning50: '#FFE7B8',
  warning100: '#FFDB94',
  warning200: '#FDC85D',
  warning300: '#F0AE28',
  warning400: '#CD8C0A',
  warning500: '#A36B00',
  warning600: '#825603',
  warning700: '#5C3D00',
  warning800: '#422C00',
  warning900: '#291B00',
  light50: '#FFFFFF',
  light100: 'rgba(255, 255, 255, 0.9)',
  light200: 'rgba(255, 255, 255, 0.8)',
  light300: 'rgba(255, 255, 255, 0.7)',
  light400: 'rgba(255, 255, 255, 0.6)',
  light500: 'rgba(255, 255, 255, 0.5)',
  light600: 'rgba(255, 255, 255, 0.4)',
  light700: 'rgba(255, 255, 255, 0.3)',
  light800: 'rgba(255, 255, 255, 0.2)',
  light900: 'rgba(255, 255, 255, 0.1)',
  white: '#FFFFFF',
  black: '#000000',
  joy: {
    name: 'color',
    values: ['primary', 'secondary', 'light', 'information', 'neutral', 'danger', 'success', 'warning'],
  },
}

export default colors
